.td[data-title] {
  position: relative;
}

.td[data-title]:hover::after {
  content: attr(data-title);
  position: absolute;
  top: calc(0% + 10px);
  left: calc(100% + 10px);
  background-color: black;
  color: #fff;
  padding: 10px;
  width: 200px;
  max-width: 400;
  font-size: 13px;
}
