.mobile-header{height: 50px; width: 100%; background-color: #A2D2FF;}
.leftMobileMenu{height: 100%; width: 300px; background: #F7F9FD; position: fixed; left: 0px; top: 0px; z-index: 9;}
.leftMobileMenu .leftnavbar_Outer{
    position: relative;
    background: #a2d2ff;
    width: 300px;
    left: 0;
    padding-left: 10%;
    padding-top: 0px;
    top: 0px;
}
.hideLeftMobileMenu{left: -300px; transition: all 0.8s;}
.showLeftMobileMenu{left: 0px;}
.mobileLogoClose{height: 50px; width: 100%; background-color: #A2D2FF; box-sizing: border-box; padding: 0px 15px;}
@media (max-width:767px){
    .pageTTL > img {display: none;}
    .pageTTL > h3 {text-align: center;
        display: block;
        width: 100%;
        margin-left: 0px;}
    .rightSideBar2 {background: rgba(0,0,0,0.8);
        position: fixed;
        top: 0;
        z-index: 1;    height: 100%;
        width: 100%; display: none;}
    .logoImg{ display: none;}
}