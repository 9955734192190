.DashCard-Outer p {
    margin-bottom: 0px;
}

.DashCard-inner {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #c7c7cc;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 7px;
}

.imagOuteCircle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
}

#color1 {
    background-color: #A2D2FF;
}

#color2 {
    background-color: #F9DC45;
}

#color3 {
    background-color: #BAEEB8;
}

#color4 {
    background-color: #F37777;
}

.DashCard-Outer h2 {
    font-size: 16px;
    font-weight: 600;
}

.DashCard-Outer .up {
    font-size: 12px;
    color: #09D804;
    margin-left: 10px;
}

.DashCard-Outer .down {
    font-size: 12px;
    color: #E20000;
    margin-left: 10px;
}

.DashCard-Outer h3 {
    color: #B3B3B3;
}

.chartSection {
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 18px 0px 0px 0px;
}

.treeCard {
    box-sizing: border-box;
    padding: 8px;
    border-right: 1px solid #c7c7c7;
}

.noBorder {
    border: 0px solid #c7c7c7;
}

.treeCard img {
    margin-left: 20px;
}

.treeCard h4.greenClr {
    font-size: 14px;
    font-weight: 600;
    color: #04BE00;
}

.treeCard h4.redClr {
    font-size: 14px;
    font-weight: 600;
    color: #E20000;
}

.treeCard h3 {
    font-size: 20px;
    font-weight: 600;
}

.Marchant_outer {
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
}

.lastTableRowNoBOrder tr:last-child td {
    border-bottom: 0px;
}

.justify-content-bitween {
    justify-content: space-between;
    width: 95%;
    margin: 20px auto 25px;
}