@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
}

.topImgTTL {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}
td.maxwtd {
  width: 250px;
}

.topImgTTL img {
  height: 23px;
}
button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.topImgTTL h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.logoImg img {
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn-theme1 {
  background: #042d5f !important;
  border: 1px solid #042d5f !important;
}

.btn-theme {
  background: #042d5f !important;
  border: 1px solid #042d5f !important;
}

.fSize18 {
  font-size: 18px;
}

.fSize16 {
  font-size: 16px !important;
}

.fSize15 {
  font-size: 15px !important;
}

.fSize20 {
  font-size: 20px !important;
}

.OrderDetailsAccordion .accordion-item {
  border: none !important;
}

.OrderDetailsAccordion .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #000;
  font-weight: 600;
  padding-left: 0px;
}

.OrderDetailsAccordion .accordion-button {
  padding-left: 0px !important;
  margin-top: 30px;
  font-weight: 600;
}

.accordion-button:focus {
  border-color: none !important;
}

.subTTLTBL tr td:nth-child(2) {
  text-align: right;
}

.theme-btn-green {
  background: #006ed4 !important;
  color: #fff !important;
}

.addresTable p {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}


.fWeight6 {
  font-weight: 600;
}

.fWeight5 {
  font-weight: 500;
}

.btn-table {
  background-color: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
  color: #006ed4 !important;
  padding: 4px 0px !important;
  width: 85px;
}

.btn-outline-theme {
  border: 1px solid #042d5f !important;
  color: #042d5f Im !important;
}

.btn-outline-theme:hover {
  border: 1px solid #042d5f !important;
  background-color: #042d5f !important;
  color: #fff !important;
}

.Marchant_outer .table {
  margin-top: 30px;
}

.Marchant_outer .table td:last-child,
.Marchant_outer .table th:last-child {
  min-width: 40px;
  text-align: right;
}

.rightSideBar {
  margin-bottom: 30px;
  width: 70%;
  float: left;
  margin-left: 3%;
}

.SKUIDROW {
  vertical-align: inherit;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #d6ebff !important;
  color: #006ff2 !important;
  border-radius: 0px;
}

.nav-pills {
  border-bottom: 1px solid #006ff2;
}

.checkBoxTable td:first-child {
  width: 94px;
}

.loanTable td,
.loanTable th {
  text-align: center;
}

.loanTable td:first-child,
.loanTable th:first-child {
  text-align: left;
}

.platinum,
.gold,
.silver,
.premium,
.paid,
.blue,
#platinum,
#gold,
#silver,
#premium,
#paid,
#blue {
  display: block;
  padding: 5px 0;
  border-radius: 15px;
  color: #fff;
  min-width: 150px;
  text-align: center;
  width: fit-content;
  font-weight: 600;
  text-transform: capitalize;
}

.platinum,
.gold,
.silver,
#platinum,
#gold,
#silver {
  color: #000;
}

.platinum,
#platinum {
  background-color: #f3f3f3;
}
.gold,
#gold {
  background-color: #ffd79cfa;
}
.silver,
#silver {
  background-color: #cac9c7fa;
}
#blue {
  color: #fff;
  background-color: #044b9f;
}
.premium.blue {
  background-color: #5ea8ff;
}
.paid.blue {
  background-color: #006ff2;
}

.couponTable .Active {
  color: #04be00;
}

.couponTable .Expired {
  color: red;
}

.CatelougeTable td.inactive {
  color: red;
}

.CatelougeTable td.active {
  color: #04be00;
}

.image_box {
  position: relative;
  width: fit-content;
  overflow: hidden;
  display: inline-block;
}

.image_box .remove_btn {
  position: absolute;
  top: 0px;
  right: -30px;
  background: #f00;
  color: #fff;
  font-size: 16px;
  width: 26px;
  height: 26px;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.image_box:hover .remove_btn {
  right: 0px;
}

.preview {
  width: 100px !important;
  height: auto !important;
  box-shadow: 0 0 10px -5px rgba(42, 42, 42, 0.795);
  border: 1px dashed #616060;
  margin-left: 10px;
  /* padding: 10px; */
}

.RFQDetails h3 {
  font-size: 18px;
  font-weight: 500;
}

.RFQDetails h4 {
  font-size: 22px;
  color: #a1a1a1;
  margin-bottom: 0px;
}

.RFQDetails p {
  font-size: 15px;
}

.lightGraytextColor {
  color: #a1a1a1;
}

.odrVw {
  margin-top: 30px;
}

.odrVw h3 {
  font-size: 16px;
  font-weight: 500;
}

.odrVw p {
  font-size: 15px;
  font-weight: 600;
  color: #a4a0a0;
}

.logsTable tr:first-child {
  background: #f8f9fb;
  border-radius: 10px;
}

.logsTable tr th:last-child {
  border-top-right-radius: 10px;
  padding: 10px !important;
}

.logsTable tr th:first-child {
  border-top-left-radius: 10px;
}

.logsTable > :not(caption) > * > * {
  padding: 0px !important;
}

.logsSec {
  margin-top: 25px;
}

.logsSec h3 {
  font-size: 23px;
  font-weight: 600;
}

.borderSetion {
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  min-height: 100px;
  width: 100%;
}

.borderSetion h2 {
  font-size: 23px;
  font-weight: 600;
}

.borderSetion table {
  font-size: 15px;
}

.borderSetion table td:first-child {
  min-width: 160px;
  font-weight: 600;
}

.borderSetion h4 {
  font-size: 17px;
}
.catalog_detail .table td {
  width: 50%;
  text-align: left;
}

.BigNum h5 {
  font-size: 26px;
  margin-bottom: 0px;
  font-weight: 600;
}

.BigNum p {
  font-size: 13px;
  color: #7a7a7a;
  font-weight: 500;
}

.TotalPurchaseTable table td:first-child {
  min-width: auto;
}

.ticketTable tr td:first-child {
  font-weight: 600;
}

.ticketTable > :not(caption) > * > * {
  padding: 0px !important;
}

.borderSetion table {
  margin-bottom: 0px;
}

.recentTrsModal h2 {
  font-size: 28px;
  font-weight: 600;
}

.recentTrsModal p {
  font-size: 15px;
  font-weight: 500;
  color: #7a7a7a;
}

.recentTrsModal h3 {
  font-size: 20px;
}

.recentTrsModal td {
  color: #646464;
  text-align: center;
  font-size: 15px;
}

.recentTrsModal th {
  text-align: center;
}

.recentTrsModal td:last-child {
  color: #e20000;
  text-align: center;
  font-size: 15px;
}

.recentTrsModal .modal-footer {
  border: none;
}

.recentTrsModal .modal-content {
  padding: 45px;
}

.paymentPoupBx {
  width: 300px !important;
  margin-top: 40px;
}

.PopUpTanls88 h1 {
  margin-top: 20px;
}

.createComission h2 {
  font-size: 20px;
  font-weight: 600;
}

.form-switch {
  padding-left: 0px !important;
}

.form-switch label {
  float: left !important;
}

.form-switch input {
  float: left !important;
  margin-left: 1.5em !important;
}

.viewDeatlTable table {
  width: 80%;
}

.proBigImg img {
  width: 100%;
  border: 1px solid #c7c7c7;
}

.proSmlImg img {
  width: 100%;
  border: 1px solid #c7c7c7;
}

.orderTailsTable tr td:first-child {
  padding: 0px;
  padding-bottom: 5px;
  font-weight: 600;
}

.orderTailsTable tr td:last-child {
  padding: 0px;
  padding-bottom: 5px;
  font-weight: 400;
}

.PackagingCir,
.ShippedCir,
.InTransitCir,
.DeliveredCir {
  height: 60px;
  width: 100px;
  background: #7b7b7b;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackagingCir img,
.ShippedCir img,
.InTransitCir img,
.DeliveredCir img {
  height: 20px;
}

.Line1,
.Line2,
.Line3 {
  width: 30%;
  height: 2px;
  background: #bebebe;
}

.statusGreen {
  background: #0e9e01;
}

.statusBlue {
  background: #006ff2;
}

.trns6567 p {
  margin-bottom: 0px;
}

.trns6567 p:last-child {
  color: #bebebe;
  font-size: 13px;
  text-align: center;
}

.UpdattableStatus {
  width: 50%;
}

.UpdattableStatus td {
  text-align: left;
  padding: 0px;
  color: #646464;
}

.UpdattableStatus {
  width: 50% !important;
}

.theme-bg-color-gray {
  background-color: #f4f4f4 !important;
}

.shipDtl p {
  margin-bottom: 6px;
}

.BuyerSellerSideOuter {
  margin-top: 50px !important;
}

.leftnavbar_Outer {
  position: fixed;
  background: #003f7a;
  width: 25%;
  left: 0;
  padding-left: 2%;
  padding-top: 30px;
  top: 0px;
}

.mobile-header {
  display: none;
}

@media (max-width: 767px) {
  .menuList li a {
    font-size: 14px;
    font-weight: 500;
  }

  .menuList img {
    height: 20px;
  }

  .mobile-header {
    display: block;
  }

  .leftMobileMenu .leftnavbar_Outer {
    background: #f7f9fd !important;
  }

  .treeCard {
    box-sizing: border-box;
    padding: 8px;
    border-right: 0px solid #c7c7c7 !important;
  }

  .chartSection .fSize18 {
    font-size: 15px !important;
  }

  .BuyerSellerSideOuter {
    margin-top: 0px !important;
  }

  .BuyerSellerSideOuter > div {
    margin-top: 25px;
  }

  .UpdattableStatus {
    width: 100% !important;
  }

  .viewDeatlTable table {
    width: 100%;
  }

  .paymentPoupBx {
    width: 100% !important;
    margin-top: 30px;
  }

  .left_menu {
    display: none;
  }

  .rightSideBar {
    width: 100%;
    margin-left: 0%;
    box-sizing: border-box;
    padding: 15px;
    padding-top: 0px;
  }

  .recentTrsModal .modal-content {
    padding: 0px;
  }
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(32 33 36) !important;
}

.createuser .flex-box {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.createuser .flex-box .form-group {
  width: 100%;
}

.createuser select {
  padding: 8px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  color: #ced4da;
}

.createuser select option:first-child {
  display: none;
}

.createuser select option {
  color: #000;
}

label.form-label {
  font-size: 15px;
  font-weight: 500;
}

.pdtCat select {
  padding: 8px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  color: #ced4da;
}

.pdtCat select option:first-child {
  display: none;
}

.pdtCat select option {
  color: #000;
}

/* edit account */
.edit-account h4 {
  font-size: 19px;
  padding-bottom: 20px;
}

.edit-account form .allborder {
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}

.edit-account .form-control label {
  color: #7b7b7b;
  display: block;
  font-size: 15px;
  padding-bottom: 15px;
}

.edit-account .form-control {
  border: unset;
  position: relative;
}

.edit-account .form-control input::placeholder {
  font-size: 13px;
}

.edit-account .form-control input {
  padding: 7px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.edit-account .form-control svg {
  position: absolute;
  right: 5%;
  top: 62%;
  color: #9b9b9b;
  font-size: 19px;
}

.edit-account .form-control label {
  color: #000;
  color: #7b7b7b;
}

.edit-account .form-control textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.edit-account .form-select {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  color: #ccc;
}

.edit-account .form-select:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  box-shadow: unset;
}

.edit-account .form .form-check-label {
  font-size: 12px;
}

.edit-account .docum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 30px;
}

.edit-account .docum h6 {
  font-size: 15px;
  color: #7b7b7b;
  padding: 0;
}

.edit-account .docum p {
  color: #7b7b7b;
  font-size: 13px;
  width: 350px;
}

.edit-account .docum button {
  background: #006ed5;
  border-radius: 5px;
  border: #006ed5;
  color: #fff;
  padding: 10px 20px;
  font-size: 15px;
}

.edit-account .buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 40px;
}

.edit-account .buttons .cancel {
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: #fff;
  color: #042d5f;
  padding: 10px 20px;
}
.edit-account .buttons .update {
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: #042d5f;
  color: #fff;
  padding: 10px 20px;
}

/* company detail */
.active-popop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.active-popop button.active {
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: #042d5f;
  color: #fff;
  padding: 10px 20px;
}
.member-popup .modal-header {
  border: unset;
}
.member-popup .modal-title.h4 {
  font-size: 18px;
}
.member-popup .form-input {
  display: flex;
  font-size: 15px;
}
.member-popup .form-input label {
  width: 40%;
  font-weight: 500;
}
.member-popup .form-input p {
  width: 60%;
}
.member-popup .form-input select {
  width: 60%;
}
.member-popup .active {
  margin: 30px 20px 30px auto;
  display: flex;
  width: 100px;
  background: #006ed5;
  font-size: 15px;
  padding: 7px 22px;
}
/* .edit-account .head-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.edit-account .head-btn button {
    background: #042D5F;
    border-radius: 5px;
    color: #fff;
    border: #042D5F;
    font-size: 15px;
    padding: 10px 20px;
}

.edit-account .form-input {
    display: flex;
    font-size: 15px;
}

.edit-account .form-input label {
    width: 35%;
}

.edit-account .form-input p {
    color: #626161;
    width: 65%;
}

.member-popup .modal-header{
    border: unset
}
.member-popup .modal-title.h4 {
    font-size: 18px;
}
.member-popup .form-input {
    display: flex;
    font-size: 15px;
}
.member-popup .form-input label{
    width: 40%;
    font-weight: 500;
}
.member-popup .form-input p{
    width: 60%;
}
.member-popup .form-input select{
    width: 60%;
}

.tab-content h6{
    font-weight: 600;
    padding-bottom: 20px;
}
.tab-content .flex-box .inputs h2 {
    font-size: 30px;
}
.tab-content .flex-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
 .tab-content .flex-box .inputs p{
    font-size: 14px;color: #7A7A7A;
} */

.borderSetion .card .image {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a5a2a2;
}
.borderSetion .card .image img {
  text-align: center;
  margin: 0 auto;
  width: 40px;
  height: 40px;
}
.borderSetion .card p {
  font-size: 13px;
  margin: 0;
}

/* ticket edit */
.ticketedit .form-group {
  font-size: 15px;
  display: flex;
  width: 100%;
}
.ticketedit .form-group label {
  font-weight: 500;
  width: 40%;
}
.ticketedit .accordion {
  padding-bottom: 50px;
}
.ticketedit .top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 50px;
}
.ticketedit .top p {
  color: #000;
  font-size: 15px;
  display: flex;
  gap: 30px;
}
.ticketedit .bottom p {
  color: #000;
  font-size: 15px;
  display: flex;
  gap: 30px;
}
.ticketedit .bottom p b {
  width: 20%;
}
.ticketedit button.accordion-button {
  background: #f8f8f9 !important;
  /* background-color: rgba(0, 0, 0, .03) !important; */
  padding: 20px 25px !important;
  border-radius: 10px !important;
}
.ticketedit button.accordion-button p {
  margin: 0;
}
.ticketedit button.accordion-button:focus {
  box-shadow: unset;
}
.ticketedit .accordion-item {
  border: 1px solid #d9d9d9 !important;
  /* border: 1px solid rgba(0, 0, 0, 0.12) !important;  */
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.ticketedit .accordion-body {
  padding: 20px;
  border-radius: 10px;
}
.ticketedit textarea {
  width: 100%;
  background: #f8f8f8;
  border: #f8f8f8;
  border-radius: 8px;
  height: 200px;
  padding: 10px 20px;
}
.ticketedit input {
  width: 100%;
  padding: 10px 20px;
  border: #f8f8f8;
  background: #f8f8f8;
  border-radius: 8px;
}
.ticketedit .buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.ticketedit button.cancel {
  background: #fff;
  border: #fff;
  color: #042d5f;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #042d5f;
}

.ticketedit button.resolve {
  background: #042d5f;
  border: 1px solid #042d5f;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}
.ticketedit .more {
  padding: 50px 0;
}

.ticketedit .more a {
  background: #042d5f;
  border: 1px solid #042d5f;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}
