.left_menu {
  width: 25%;
  float: left;
  /* background: #006ED4; */
  box-sizing: border-box;
  padding: 15px;
  height: 100vh;
}

.menuList {
  list-style: none;
  padding-left: 0px;
  overflow-y: scroll;
  /* height: 567px; */
  padding-bottom: 100px;
}

.menuList::-webkit-scrollbar {
  width: 2em;
  height: 2em;
}

.menuList li a {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  padding: 10px 10px;
  display: block;
}

.menuList li a.active {
  background: #ffffff;
  border-radius: 5px;
  color: #007cc9;
}

.menuList li a.active div {
  color: #007cc9;
}
.menuList a.active img {
  height: 23px;
  margin-right: 5px;
}

.menuList li a.active img {
  filter: invert(29%) sepia(57%) saturate(6206%) hue-rotate(188deg)
    brightness(99%) contrast(101%);
}

.menuList img {
  height: 23px;
  margin-right: 5px;
}
.logoImg img{
    max-width: 70%;
    height: auto;
}

.nav_item_img {
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(338deg)
    brightness(100%) contrast(103%);
}

.topImgTTL h3 {
  margin-left: 10px;
}

.searchBtn {
  background-color: #86b4df;
  cursor: pointer;
}

/* .SKUIDROW td{font-size: 12px; font-weight: 500; color: #939191;} */
.SKUIDTable tr {
  max-width: 300px;
}

.SKUIDTable th {
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 500;
}

.SKUIDTable td {
  color: #939191;
  font-size: 12px;
  font-weight: 500;
}

.ProductName {
  font-size: 15px;
  font-weight: 500;
}

.theme-color {
  color: #006ed5;
}
.leftnavbar_Outer a.logout {
  height: 60px;
  display: inline-block;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
